// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-the-school-js": () => import("./../../../src/pages/about-the-school.js" /* webpackChunkName: "component---src-pages-about-the-school-js" */),
  "component---src-pages-classes-js": () => import("./../../../src/pages/classes.js" /* webpackChunkName: "component---src-pages-classes-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-policies-js": () => import("./../../../src/pages/policies.js" /* webpackChunkName: "component---src-pages-policies-js" */),
  "component---src-templates-all-event-posts-js": () => import("./../../../src/templates/allEventPosts.js" /* webpackChunkName: "component---src-templates-all-event-posts-js" */),
  "component---src-templates-all-news-posts-js": () => import("./../../../src/templates/allNewsPosts.js" /* webpackChunkName: "component---src-templates-all-news-posts-js" */),
  "component---src-templates-class-page-js": () => import("./../../../src/templates/classPage.js" /* webpackChunkName: "component---src-templates-class-page-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-policy-page-js": () => import("./../../../src/templates/policyPage.js" /* webpackChunkName: "component---src-templates-policy-page-js" */),
  "component---src-templates-redeploy-js": () => import("./../../../src/templates/redeploy.js" /* webpackChunkName: "component---src-templates-redeploy-js" */),
  "component---src-templates-single-news-post-js": () => import("./../../../src/templates/singleNewsPost.js" /* webpackChunkName: "component---src-templates-single-news-post-js" */)
}

